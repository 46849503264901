var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.resolutionScreen < 500)?_c('div',{staticClass:"text-center"},[_c('v-snackbar',{staticClass:"v-snack__content",attrs:{"timeout":_vm.listrecheckdownload.timeout,"bottom":""},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',{staticClass:"mt-3 pa-3",attrs:{"color":"transparent","elevation":"0","width":"320"}},[(_vm.listrecheckdownload.status === 'start')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.checkpleaseweit"))+" ")]):(_vm.listrecheckdownload.status === 'checkallemail')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.checkdoc"))+" "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.listrecheckdownload.nowemail)+" / "+_vm._s(_vm.listrecheckdownload.allcheckemail))])]):(
          _vm.listrecheckdownload.status === 'download' &&
            _vm.listrecheckdownload.percen === 100
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.downloaddocsuccess"))+" "),_c('span',{staticClass:"float-right"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]):(
          _vm.listrecheckdownload.status === 'download' &&
            _vm.listrecheckdownload.percen >= 0
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.nowdownload"))+" "),(_vm.listrecheckdownload.percen > 0)?_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.listrecheckdownload.percen))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"px-n12 mr-n2 mt-n2"},[(_vm.listrecheckdownload.status === 'start')?_c('v-progress-linear',{attrs:{"color":"green","indeterminate":"","height":10}}):_vm._e()],1)],1)],1):_c('div',{staticClass:"text-center"},[_c('v-snackbar',{staticClass:"v-snack__content",attrs:{"timeout":_vm.listrecheckdownload.timeout,"bottom":""},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[_c('v-card',{staticClass:"mt-3 pa-3",attrs:{"color":"transparent","elevation":"0","width":"500"}},[(_vm.listrecheckdownload.status === 'start')?_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("adminrecheckimap.checkpleaseweit")))])]):(_vm.listrecheckdownload.status === 'checkallemail')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.checkdoc"))+" "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.listrecheckdownload.nowemail)+" / "+_vm._s(_vm.listrecheckdownload.allcheckemail))])]):(
          _vm.listrecheckdownload.status === 'download' &&
            _vm.listrecheckdownload.percen === 100
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.downloaddocsuccess"))+" "),_c('span',{staticClass:"float-right"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]):(
          _vm.listrecheckdownload.status === 'download' &&
            _vm.listrecheckdownload.percen >= 0
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("adminrecheckimap.nowdownload"))+" "),(_vm.listrecheckdownload.percen > 0)?_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.listrecheckdownload.percen))]):_c('v-progress-circular',{staticClass:"mt-n1 float-right",attrs:{"indeterminate":"","color":"green","height":"8"}})],1):_vm._e()]),_c('div',{staticClass:"px-n12 mr-n2 mt-n2"},[(_vm.listrecheckdownload.status === 'start')?_c('v-progress-linear',{attrs:{"color":"green","indeterminate":"","height":10}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }